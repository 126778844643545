<template>
  <div>
    <spacer :y="6"/>
    <contents-box title="テストアカウント管理">
      <spacer :y="2"/>
      <p>オンラインタブの「CSV出力」および「オンライン・バーチャル健康相談」の項目にて、テストアカウントとして除外する利用者を設定します。<br>
        『テストアカウントを除外する』にチェックをが入っている際は、ダウンロードされるCSVおよび表示される一覧から設定したアカウントが除外されます。</p>
      <spacer :y="2" />
        <div :class="$style.excludeMails">
          <div :class="$style.excludeMails_wrap">
            <div :class="$style.excludeMails_save">
              <input type="email" :class="$style.input_area" v-model="addExcludeAccount">
              <spacer :x="1"/>
              <basic-btn
                tag="button"
                :disabled="!addExcludeAccount"
                @click="saveExcludeMail">
                <sapn>追加</sapn>
              </basic-btn>
            </div>
            <spacer :y="2"/>
            <b>除外メールアドレス一覧</b>
            <spacer :y="1"/>
            <ul v-if="excludeData.length">
              <li v-for="mail in excludeData" :key="mail">
                <div v-if="mail.user && mail.user.email">
                  {{ mail.user.email }}
                  <span :class="[$style.icon, $style.delete_icon]" @click="deleteExcludes(mail.id)"><i class="fa-solid fa-delete-left"></i></span>
                </div>
                <spacer :y="1"/>
              </li>
            </ul>
            <p v-else>テストアカウントメールアドレス登録なし</p>
          </div>
        </div>
    </contents-box>

    <spacer :y="6"/>
    <contents-box title="祝日管理">
      <loader-simple :flag="flag.loader">
        <spacer :y="2"/>

        <!-- メイン部 -->
        <div v-show="showMainArea">
          <div>
            <div :class="$style.title">
              <div :class="$style.title_text">
                <label-box :class="$style.label" label="CSVインポート" size="sm" color="gray"/>
                <div class="form-parts-file">
                  <input
                    :class="$style.fileinput"
                    id="wtt-holiday-import-file"
                    type="file"
                    name="file"
                    accept=".csv"
                    v-on:change="updateFile">
                  <label :class="$style.filelabel" for="wtt-holiday-import-file">ファイルを選択</label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-parts-file">
          </div>
          <spacer :y="2"/>
          <hr>
          <spacer :y="5"/>
          <calendar
            :key="calendarKey"
            :information="true"
            :schedule="schedule"
            :selectableSunSat="true"
            :selectableHoliday="true"
            :initialDate="calendarInitialDate"
            v-on:sendDate="receiveHolidayDate" />
          <spacer :y="5"/>
          <contents-box v-show="date" :title="formatTimestamp(date, 'YYYY年MM月DD日')">
            <div :class="$style.wrapper">
              <div :class="$style.row">
                <div :class="$style.detail">
                  <div class="form-parts">
                    <input
                      type="text"
                      id="wtt-holiday-input"
                      placeholder="祝日名を入力してください"
                      :class="$style.text"
                      v-model="inputDateLabel">
                  </div>
                </div>
              </div>
            </div>
            <div :class="$style.flex">
              <basic-btn tag="button" v-on:click="clickAction" :disabled="disabledAction">{{ buttonLabel }}</basic-btn>
              <spacer v-if="!isRegisterMode" :x="2"/>
              <basic-btn v-if="!isRegisterMode" tag="button" type="bdr" v-on:click="clickDelete" :disabled="disabledDelete">削除する</basic-btn>
            </div>
          </contents-box>
        </div>

        <!-- インポート部 -->
        <div v-if="showImportArea">
          <div :class="$style.title">
            <div :class="$style.title_text">
              <label-box :class="$style.label" label="ファイル名" size="sm" color="gray"/>
              <p>{{ importFileName }}</p>
            </div>
          </div>
          <spacer :y="5"/>

          <!-- インポートOK -->
          <div v-if="!importFile.error">
            <h4>以下の内容で祝日をインポートします。よろしいですか？</h4>
            <spacer :y="3"/>
            <div :class="$style.flex">
              <basic-btn
                tag="button"
                :diasbled="flag.loader"
                v-on:click="clickImport">実行する</basic-btn>
              <spacer :x="2"/>
              <basic-btn
                tag="button"
                type="bdr"
                :diasbled="flag.loader"
                v-on:click="clickCancel">キャンセル</basic-btn>
            </div>
            <spacer :y="5"/>
            <contents-box
              v-if="importFile.recordsNew"
              title="インポートされるデータ">
              <div
                v-for="row in importFile.recordsNew" :key="row.date"
                :class="$style.wrapper">
                <div :class="$style.title_text">
                  <label-box :class="$style.label" :label="row.date" size="sm" color="gray"/>
                  <p>{{ row.label }}</p>
                </div>
                <spacer :y="1"/>
              </div>
            </contents-box>
            <spacer :y="3"/>
            <contents-box
              v-if="importFile.recordsRegistered && importFile.recordsRegistered.length"
              title="インポートされないデータ（過去・または登録済み日付）">
              <div
                v-for="row in importFile.recordsRegistered" :key="row.date"
                :class="$style.wrapper">
                <div :class="$style.title_text">
                  <label-box :class="$style.label" :label="row.date" size="sm" color="gray"/>
                  <p>{{ row.label }}</p>
                </div>
                <spacer :y="1"/>
              </div>
            </contents-box>
          </div>

          <!-- インポートNG -->
          <div v-else>
            <div :class="$style.error">
              <span :class="$style.red">以下の理由によりデータをインポートできません。</span>
            </div>
            <spacer :y="3"/>
            <div :class="$style.flex">
              <basic-btn tag="button" type="bdr" v-on:click="clickCancel">戻る</basic-btn>
            </div>
            <spacer :y="5"/>
            <contents-box :title="importFile.errorMessage">
              <div
                v-for="row in importFile.errorRecords" :key="row.rowNum"
                :class="$style.wrapper">
                <div :class="$style.title_text">
                  <label-box :class="$style.label" :label="`${row.rowNum} 行目`" size="sm" color="gray"/>
                  <p>{{ `${row.errorMessage}（${row.data}）` }}</p>
                </div>
                <spacer :y="1"/>
              </div>
            </contents-box>
          </div>
        </div>

      </loader-simple>
    </contents-box>

    <spacer :y="6"/>
    <contents-box title="長期休暇">
      <loader-simple :flag="flag.loader">
        <div v-if="!flag.loader">
          <spacer :y="1"/>
          <p :class="$style.font_lg">テキスト健康相談停止設定</p>
          <spacer :y="2"/>
          <div :class="$style.date">
            <dl>
              <dt :class="$style.font_md"><i class="fa-solid fa-calendar-days"></i>停止期間</dt>
              <dd>
                <div>
                  <date-small-picker
                    :class="$style.font_md"
                    :name="'disableTextCounselingTime'"
                    :type="'datetime'"
                    v-model="disableTextCounselingTime.start"
                    v-on:sendDate="receiveDate($event, 'start')"
                    :minute-increment="60"
                    is24hr />
                  <spacer :y="1"/>
                </div>
                <div>
                  <date-small-picker
                    :name="'disableTextCounselingTime'"
                    :type="'datetime'"
                    v-model="disableTextCounselingTime.end"
                    v-on:sendDate="receiveDate($event, 'end')"
                    :minute-increment="60"
                    is24hr />
                  <spacer :y="1"/>
                </div>
              </dd>
            </dl>
          </div>
          <spacer :y="2"/>
          <dl :class="$style.checkbox">
            <dd>
              <input
                type="checkbox"
                id="disableText"
                :true-value="1"
                :false-value="0"
                v-model="isDisableTextCounseling">
              <label for="disableText" :class="$style.font_md">テキスト健康相談を停止する</label>
            </dd>
          </dl>
          <spacer :y="1"/>
          <p :class="$style.font_assistance">期間設定にかかわらず相談受付を停止します。<br>チェック無の状態で停止開始時間＞（自動）チェック有<br>チェック有の状態で停止終了時間＞（自動）チェック無</p>
        </div>
        <spacer :y="5"/>
        <div v-if="!flag.loader">
          <p :class="$style.font_lg">案内文言</p>
          <spacer :y="2"/>
          <div :class="$style.date">
            <dl>
              <dt :class="$style.font_md"><i class="fa-solid fa-calendar-days"></i>表示期間</dt>
              <dd>
                <div>
                  <date-small-picker
                    :name="'showNoticeTime'"
                    :type="'datetime'"
                    v-model="showNoticeTime.start"
                    v-on:sendDate="receiveDate($event, 'start')"
                    :minute-increment="60"
                    is24hr />
                  <spacer :y="1"/>
                </div>
                <div>
                  <date-small-picker
                    :name="'showNoticeTime'"
                    :type="'datetime'"
                    v-model="showNoticeTime.end"
                    v-on:sendDate="receiveDate($event, 'end')"
                    :minute-increment="60"
                    is24hr />
                  <spacer :y="1"/>
                </div>
              </dd>
            </dl>
          </div>
          <spacer :y="2"/>
          <dl :class="$style.checkbox">
            <dd>
              <input
                type="checkbox"
                id="showNotice"
                :true-value="1"
                :false-value="0"
                v-model="isShowEmphasisNotice">
              <label for="showNotice" :class="$style.font_md">案内文言を表示する</label>
            </dd>
          </dl>
          <spacer :y="1"/>
          <p :class="$style.font_assistance">期間設定にかかわらず案内文を表示します。<br>チェック無の状態で停止開始時間＞（自動）チェック有<br>チェック有の状態で停止終了時間＞（自動）チェック無</p>
          <spacer :y="3"/>
          <textarea
            v-model="disableEmphasisNotice"
            :class="$style.textarea"
            rows="6"></textarea>
          <p :class="$style.font_assistance">受付停止の案内を各所で表示します。</p>
        </div>
        <spacer :y="2"/>
        <btn-container>
          <basic-btn tag="button" v-if="!flag.loader" @click="updateTextDisableStatus">設定する</basic-btn>
        </btn-container>
      </loader-simple>
    </contents-box>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';
import Calendar from '@/views/components/DatePicker.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import DateSmallPicker from '@/views/components/DateSmallPicker.vue';

export default {
  name: 'holidays',
  mixins: [cf],
  components: {
    Calendar,
    BtnContainer,
    BasicBtn,
    ContentsBox,
    LabelBox,
    LoaderSimple,
    Spacer,
    DateSmallPicker,
  },
  data() {
    return {
      date: '',
      inputDateLabel: '',
      flag: {
        loader: true,
      },
      schedule: [],
      calendarKey: new Date(),
      calendarInitialDate: '', // カレンダー初期選択日
      importFile: null,
      importFileName: '',
      isDisableTextCounseling: 0,
      isShowEmphasisNotice: 0,
      disableEmphasisNotice: '',
      disableTextCounselingTime: {
        start: null,
        end: null,
      },
      showNoticeTime: {
        start: null,
        end: null,
      },
      addExcludeAccount: null,
      excludeMails: [],
      excludeData: [],
    };
  },
  computed: {
    ...mapState(['user', 'holiday', 'helper']),
    dateLabel() {
      if (!this.date || !this.selectedHoliday) {
        return '';
      }
      return this.selectedHoliday.label;
    },
    selectedHoliday() {
      if (!this.date) {
        return null;
      }
      return this.holiday.list.find((row) => row.date === this.date);
    },
    isRegisterMode() {
      return !this.selectedHoliday;
    },
    canUpdate() {
      return this.inputDateLabel !== this.selectedHoliday.label;
    },
    buttonLabel() {
      return this.isRegisterMode ? '登録する' : '更新する';
    },
    disabledAction() {
      return !this.inputDateLabel || (!this.isRegisterMode && !this.canUpdate);
    },
    disabledDelete() {
      return !this.inputDateLabel;
    },
    showMainArea() {
      return !this.flag.loader && !this.importFile;
    },
    showImportArea() {
      return !this.flag.loader && !!this.importFile;
    },
  },
  created() {
    if (this.holiday.list.length) {
      this.drawCalendar();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'holiday/setHoliday') {
          this.drawCalendar();
        }
      });
    }
    this.setTextDisableStatus();
    this.getExcludeMails();
  },
  methods: {
    receiveDate(args, type) {
      if (args.date === 'Invalid date') this[args.name][type] = null;
      else this[args.name][type] = args.date;
    },
    setTextDisableStatus() {
      const optionData = this.helper.textDisableStatus;
      /** checkbox */
      this.isDisableTextCounseling = optionData.disable_text_counseling;
      this.isShowEmphasisNotice = optionData.show_emphasis_notice;
      /** datetime */
      const fmt = 'YYYY-MM-DD HH:00:00';
      if (optionData.disable_text_counseling_start || optionData.disable_text_counseling_end) {
        this.disableTextCounselingTime.start = moment(optionData.disable_text_counseling_start).format(fmt);
        this.disableTextCounselingTime.end = moment(optionData.disable_text_counseling_end).format(fmt);
      }
      if (optionData.notice_show_start || optionData.notice_show_end) {
        this.showNoticeTime.start = moment(optionData.notice_show_start).format(fmt);
        this.showNoticeTime.end = moment(optionData.notice_show_end).format(fmt);
      }
      /** notice text */
      this.disableEmphasisNotice = this.helper.textDisableStatus.emphasis_notice;
    },
    refreshHolidays() {
      this.calendarInitialDate = this.date; // 選択中の日付を退避
      this.date = '';
      this.inputDateLabel = '';
      this.$store.dispatch('holiday/setHoliday'); // action -> mutation -> drawCalendar
      setTimeout(() => { this.drawCalendar(); }, 200);
    },
    drawCalendar() {
      this.schedule = this.holiday.list.map((row) => {
        const data = {
          id: row.id,
          color: 'var(--orange-main)',
          date: row.date,
          label: row.label,
        };
        return data;
      });
      this.calendarKey = new Date(); // keyを更新することでカレンダーを再描画する
      this.flag.loader = false; // 本体を表示
    },
    receiveHolidayDate(date) {
      this.date = date;
      this.inputDateLabel = this.selectedHoliday ? this.selectedHoliday.label : '';
      this.$nextTick(() => {
        document.getElementById('wtt-holiday-input').focus();
      });

    },
    async clickAction() {
      let url;
      let data;
      this.flag.loader = true; // 処理中は本体をいったん隠す
      if (this.isRegisterMode) {
        // 登録
        url = '/v1/holiday/set/register';
        data = {
          date: this.date,
          label: this.inputDateLabel,
        };
      } else {
        // 更新
        url = '/v1/holiday/set/updater';
        data = {
          id: this.selectedHoliday.id,
          label: this.inputDateLabel,
        };
      }
      try {
        await this.axios({
          method: 'POST',
          url,
          data,
        });
        // カレンダー最新化
        this.refreshHolidays();
      } catch (error) {
        console.error(error);
        alert('祝日の設定に失敗しました。');
      }
    },
    async clickDelete() {
      if (!confirm('選択中の祝日を削除します。よろしいですか？')) {
        return;
      }
      this.flag.loader = true; // 処理中は本体をいったん隠す
      try {
        await this.axios({
          method: 'POST',
          url: '/v1/holiday/set/delete',
          data: {
            id: this.selectedHoliday.id,
          },
        });
        // カレンダーの最新化
        this.refreshHolidays();
      } catch (error) {
        console.error(error);
        alert('祝日の削除に失敗しました。');
      }
    },
    async updateFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      // files.lengthが0の場合はキャンセル時
      if (!files.length) {
        return;
      }
      this.flag.loader = true; // ローディング開始
      const file = files[0];
      this.importFileName = file.name;

      try {
        // ファイル読み込み
        const reader = new FileReader();
        reader.readAsText(file, 'Shift_JIS');
        reader.onload = () => {
          let lines = reader.result.split(/\r\n|\n|\r/); // 改行コードすべてに対応
          lines.shift(); // 先頭はヘッダ行なので除外
          // 各種チェック
          const checkMethods = [
            'checkImportRecordExists', // インポートデータの存在チェック
            'checkImportRecordValidate', // インポートデータの不正チェック
            'checkImportRecordDuplicate', // インポートデータの日付重複チェック
          ];
          const hasError = checkMethods.some((checkMethod) => {
            const result = this[checkMethod](lines);
            if (result.error) {
              this.importFile = {
                error: true,
                errorMessage: result.errorMessage,
                errorRecords: result.errorRecords,
              };
              return true;
            }
            return false;
          });
          if (hasError) {
            return;
          }

          // 登録/非登録振り分け
          lines = lines.filter((row) => !!row); // 空行を取り除く
          const recordsNew = [];
          const recordsRegistered = [];
          lines.forEach((row) => {
            const data = row.split(',');
            const date = this.formatTimestamp(data[0], 'YYYY-MM-DD');
            const record = {
              date,
              label: data[1],
            };

            // 過去の祝日を除外する
            const now = this.formatTimestamp(new Date(), 'YYYY-MM-DD');
            const thisYear = moment(now).year();
            const holidayYear = moment(date).year();
            const past = holidayYear < thisYear;
            // 登録済み
            const exists = this.holiday.list.find((holiday) => holiday.date === date);
            if (exists || past) {
              recordsRegistered.push(record);
            } else {
              recordsNew.push(record);
            }
          });
          if (recordsNew.length === 0) {
            // すべてのデータが登録済みのためインポート不要
            this.importFile = {
              error: true,
              errorMessage: 'すべての対象日付データが既に登録されているため、インポートできません。',
              errorRecords: [],
            };
            return;
          }

          // 読み込みが完了したらインポート部を表示
          this.importFile = {
            error: false,
            recordsNew,
            recordsRegistered,
          };
        };
      } finally {
        this.flag.loader = false; // ローディング終了
      }
    },
    // インポートデータの存在チェック
    checkImportRecordExists(lines) {
      const rows = lines.filter((row) => !!row); // 空行を取り除く
      const error = !rows || rows.length === 0;
      return {
        error,
        errorMessage: error ? 'インポート対象のデータが入力されていません。' : '',
        errorRecords: [],
      };
    },
    // インポートデータの不正チェック
    checkImportRecordValidate(lines) {
      const errorRecords = [];
      lines.forEach((row, i) => {
        // 有効行に対してのみ実施
        if (row) {
          const data = row.split(',');
          const rowNum = i + 2; // ヘッダ行分を考慮
          if (data.length !== 2) {
            // データ構成チェック
            errorRecords.push({
              rowNum,
              errorMessage: '"日付,祝日名"のフォーマットで入力されていません',
              data: row,
            });
          } else if (this.formatTimestamp(data[0], 'YYYY-MM-DD') === 'Invalid date') {
            // 日付妥当性チェック
            errorRecords.push({
              rowNum,
              errorMessage: '日付が不正です',
              data: row,
            });
          } else if (!data[1]) {
            // ラベル入力チェック
            errorRecords.push({
              rowNum,
              errorMessage: '祝日名が未入力です',
              data: row,
            });
          }
        }
      });
      const error = !!errorRecords.length;
      return {
        error,
        errorMessage: error ? '不正なデータが入力されています。' : '',
        errorRecords,
      };
    },
    // インポートデータの日付重複チェック
    checkImportRecordDuplicate(lines) {
      const errorRecords = [];
      const datePool = [];
      lines.forEach((row, i) => {
        // 有効行に対してのみ実施
        if (row) {
          const data = row.split(',');
          const rowNum = i + 2; // ヘッダ行分を考慮
          const date = this.formatTimestamp(data[0], 'YYYY-MM-DD');
          const found = datePool.find((pool) => pool.date === date);
          if (found) {
            errorRecords.push({
              rowNum,
              errorMessage: `対象の日付は ${found.rowNum} 行目ですでに入力されています`,
              data: row,
            });
          }
          datePool.push({
            date,
            rowNum,
          });
        }
      });
      const error = !!errorRecords.length;
      return {
        error,
        errorMessage: error ? '日付が重複しています。' : '',
        errorRecords,
      };
    },
    async clickImport() {
      this.flag.loader = true; // 処理中は本体をいったん隠す
      try {
        const asyncProcessse = [];
        this.importFile.recordsNew.forEach((row) => {
          asyncProcessse.push(this.axios({
            method: 'POST',
            url: '/v1/holiday/set/register',
            data: {
              date: row.date,
              label: row.label,
            },
          }));
        });
        await Promise.all(asyncProcessse);
        // インポート終了
        this.clickCancel();
        // カレンダー最新化
        this.refreshHolidays();
      } catch (error) {
        console.error(error);
        alert('祝日のインポートに失敗しました。');
      }
    },
    clickCancel() {
      this.importFile = null;
      // ファイル解放
      document.getElementById('wtt-holiday-import-file').value = '';
    },
    async updateTextDisableStatus() {
      if (moment(this.disableTextCounselingTime.start).isSameOrAfter(this.disableTextCounselingTime.end, 'hour')
        || moment(this.showNoticeTime.start).isSameOrAfter(this.showNoticeTime.end, 'hour')) {
        return alert('停止・表示期間の設定が不正です。設定内容を確認してください。');
      }

      try {
        const fmt = 'YYYY-MM-DD HH:00:00';
        await this.axios({
          method: 'POST',
          url: '/v1/option/set/updater',
          data: {
            id: this.helper.textDisableStatus.id,
            disable_text_counseling: this.isDisableTextCounseling,
            disable_text_counseling_start: moment(this.disableTextCounselingTime.start).format(fmt),
            disable_text_counseling_end: moment(this.disableTextCounselingTime.end).format(fmt),
            show_emphasis_notice: this.isShowEmphasisNotice,
            notice_show_start: moment(this.showNoticeTime.start).format(fmt),
            notice_show_end: moment(this.showNoticeTime.end).format(fmt),
            emphasis_notice: this.disableEmphasisNotice,
          },
        })
          .then((response) => {
            const res = response.data;
            this.$store.dispatch('helper/getTextDisableStatus');
            if (res.updated) {
              alert('長期休暇設定を更新しました');
            }
          })
          .catch((error) => {
            console.log(error);
            alert('長期休暇設定の更新失敗しました。\n再度お試しください。');
          });
      } catch (error) {
        console.error(error);
        alert('長期休暇設定の更新失敗しました。\n再度お試しください。');
      }
    },
    // テストアカウントのメールアドレスをすべて取得
    getExcludeMails() {
      const params = {
        flag: 1,
      };

      this.axios({
        method: 'GET',
        url: '/v1/csvExcludeUsers/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.csvExcludeUsers;
          this.excludeData = res;
          res.forEach((account) => {
            // console.log(account);
            this.excludeMails.push(account.email);
          });
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    // 除外メールアドレス保存
    saveExcludeMail() {
      if (this.excludeMails.includes(this.addExcludeAccount)) {
        console.log('duplicate');
        alert(`${this.addExcludeAccount} はすでに除外メールアドレスに登録されています。`);
        return true;
      }

      const params = { email: this.addExcludeAccount };
      const exeFlag = confirm(`${this.addExcludeAccount}をテストアカウントに登録します`);
      if (!exeFlag) return true;

      this.axios({
        method: 'POST',
        url: '/v1/csvExcludeUsers/set/register',
        params,
      })
        .then((response) => {
          console.log(response);
          this.addExcludeAccount = null;
          this.excludeMails = [];
          this.getExcludeMails();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          alert('テストアカウントの追加に失敗しました。\n登録するメールアドレスをお確かめの上もう一度お試しください');
        });
    },

    // 除外メールアドレス削除
    async deleteExcludes(id) {
      const execFlag = confirm('このアカウントをテストアカウントから削除します');
      if (!execFlag) return true;

      const data = {
        id,
        flag: 999,
      };

      this.axios({
        method: 'POST',
        url: '/v1/csvExcludeUsers/set/updater',
        data,
      })
        .then((response) => {
          console.log(response);
          this.addExcludeAccount = null;
          this.excludeMails = [];
          this.getExcludeMails();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error.message);
        });
    },
  },
};
</script>

<style lang="scss" module>
.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.fileinput {
  display: none;
}
.filelabel {
  font-size: 12px;
  padding: 8px 20px;
  border-radius: 20px;
  background-color: var(--gray-main);
  // background-color: #666;
  // color: #fff;
  display: inline-block;
  border: none;
}
.text {
  font-size: 20px;
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
  &.bdr {
    background-color: transparent;
    color: var(--black);
    border: 1px solid var(--black);
  }
}
.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .icon {
    font-size: 20px;
    color: var(--gray-side);
  }
}
.icon {
  color: var(--gray-side)
}
.delete_icon {
  cursor: pointer;
}

.input {
  margin-left: 0;
  input {
    width: 100%;
    padding: 13px;
    border-radius: 8px;
    border: none;
    background-color: var(--gray-sub);
    outline: none;
  }
}
.message {
  font-size: 12px;
}
.label {
  width: 160px;
  margin-right: 20px;
}

.error {
  padding: 13px;
  border: 1px solid var(--pink);
  border-radius: 8px;
}
.red {
  color: var(--pink);
  font-size: 16px;
}
.bold {
  font-weight: bold;
}
.font {
  &_assistance {
    font-size: 12px;
    color: gray;
    font-weight: bold;
  }
  &_md {
    font-weight: bold;
    font-size: 14px;
  }
  &_lg {
    font-weight: bold;
    font-size: 16px;
  }
}
.checkbox {
  display: flex;
  margin: 0;
  > dt {
    width: 9em;
  }
  > dd {
    margin-inline-start: 0;
    align-items: center;
  }
}
.textarea {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  resize: vertical;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.date {
  flex: 1;
  margin-left: 2px;
  dl {
    display: flex;
    align-items: center;
    margin: 0;
  }
  dt {
    width: 7em;
    i {
      margin-right: .5em;
    }
  }
  dd {
    flex: 1;
    display: flex;
    margin-left: 1em;
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        margin-left: 1em;
        &:before {
          content: "~";
          margin-right: 1em;
        }
      }
    }
  }
}
.select {
  padding: 13px;
  height: 100%;
  text-align: center;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  appearance: none;
  font-size: 16px;
  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.excludeMails {
  display: block;
  &_button {
    cursor: pointer;
  }
  &_wrap {
    width: 100%;
  }
  &_save {
    display: flex;
    width: 40%;
    justify-content: space-between;
  }
  &_supplement {
    font-size: .8em;
  }
}
.input_area {
  width: 250px;
  resize: vertical;
  overflow: scroll;
  border: none;
  outline: none;
  background-color: var(--gray-sub);
  border-radius: 10px;
  padding: 8px;
}
</style>
