<template>
  <div>
    <spacer :y="6" />
    <contents-box
      title="Welcome to talk メンタルヘルス研修"
      icon="<i class='fa-solid fa-user-doctor'></i>">
      <div
        v-for="movie in movies"
        :key="movie">
        <spacer :y="6"/>
        <div>
          <h3 :class="$style.title" v-html="movie.title"></h3>
          <video :class="$style.video" controls>
            <source :src="movie.path" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
          </video>
        </div>
      </div>
    </contents-box>

    <spacer :y="3" />
    <div :class="$style.note">
      <p>【受講の際の禁止事項】<br>
      ・撮影・録画・録音・ダウンロード<br>
      ・SNS等への投稿転載<br>
      ・研修資料の複製・転用・転載・漏洩<br>
      ・研修アーカイブURLの無断共有</p>
    </div>

    <spacer :y="8"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'trainingKDDI',
  components: {
    ContentsBox,
    Spacer,
  },
  mixins: [cf],
  data() {
    return {
      movies: [
        {
          title: '二期生 Welcome to talk メンタルヘルス研修（第2回） ※アクセス期限：12/23まで',
          path: 'https://welcometotalk.s3.ap-northeast-1.amazonaws.com/production/Zoom/training/2024/1122/GMT20241122-060309_Recording_1920x1200.mp4',
        },
        {
          title: '二期生 Welcome to talk メンタルヘルス研修（第1回） ※アクセス期限：12/16まで',
          path: 'https://welcometotalk.s3.ap-northeast-1.amazonaws.com/production/Zoom/training/2024/1114/GMT20241114-060005_Recording_1920x1080.mp4',
        },
      ],
    };
  },
  created() {

  },
  computed: {
    ...mapState(['user', 'page']),
  },
  methods: {

  },
};
</script>

<style lang="scss" module>
.title {
  font-size: 20px;
  margin-bottom: 1rem;
}
.video {
  width: 100%;
}
.note {
  font-size: 12px;
  display: flex;
  p {
    margin-left: auto;
  }
}
</style>
